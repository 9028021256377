<template>
  <div class="catalog-landing">
    <div class="landing__content container">
      <h1 class="title">Bienvenue sur Delicity</h1>
      <span class="welcome-text">Bienvenue sur Delicity, la premiere plateforme equitable qui fait du bien aux clients, aux restaurants et aux livreurs</span>

      <div class="content__setup" data-cy="onboarding-setup">


        <div class="setup__item setup__address" :class="{'setup__address-error' : addressError}">
          <div class="error-message animate__animated animate__fadeIn animate__faster" v-if="addressError" data-cy="address_error"><i class="material-icons notranslate">error</i><small>{{addressError}}</small></div>

          <div class="item__icon"><i class="material-icons notranslate">place</i></div>
          <vue-google-autocomplete
              id="catalogAutocomplete"
              classname="item__input"
              :placeholder="$t('enter_address')"
              country="fr"
              ref="catalogAutocomplete"
              v-on:placechanged="selectAddress">
          </vue-google-autocomplete>

          <a href="#" data-cy="current-position" class="item__link" @click.prevent="currentPosition" v-if="!locationPermissionError && !isCurrentPosition && !isCurrentPositionLoading"><i class="material-icons notranslate-outlined">near_me</i>Ma position</a>
          <ui-progress-circular color="black" class="item__loading" v-if="isCurrentPositionLoading"></ui-progress-circular>
          <small v-if="locationPermissionError" class="item__error" data-cy="geolocation-error">Position désactivée...</small>
        </div>


        <div class="setup__item setup__select" @click="$root.$emit('openFilterType')" data-cy="open-type-filter-onboarding">
          <div class="item__icon">
            <i v-if="filter.type === 'delivery'" class="material-icons notranslate">moped</i>
            <i v-if="filter.type === 'pickup'" class="material-icons notranslate">directions_run</i>
          </div>
          <div class="item__value">
            {{$t(filter.type)}}
          </div>
          <i class="material-icons notranslate item__chevron">arrow_drop_down</i>
        </div>


        <div class="setup__item setup__select border-0"  @click="$root.$emit('openFilterTime')" data-cy="open-time-filter-onboarding">
          <div class="item__icon"><i class="material-icons notranslate">update</i></div>
          <div class="item__value" v-if="filter.when === 'now'">Maintenant</div>
          <div class="item__value" v-if="filter.when === 'schedule_for_later'"> {{scheduled}}</div>

          <i class="material-icons notranslate item__chevron">arrow_drop_down</i>
        </div>


        <div class="setup__button">
          <button @click="saveSetup" data-cy="save-onboarding">
            <i class="material-icons notranslate" v-if="!setupLoading">arrow_forward</i>
            <ui-progress-circular color="white" v-if="setupLoading"></ui-progress-circular>
          </button>
        </div>

      </div>
    </div>

    <div class="landing__illustration">
      <div class="container">
        <img src="https://delicity.b-cdn.net/front/img/delicity_bag.svg" class="bag">
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import moment from "moment";

export default {
  data() {
    return {
      setupLoading: false,
      locationLoading: false,
      locationError: false,
      locationPermissionError: false,
      address: false,
      addressError: false,
      isCurrentPosition: false,
      isCurrentPositionLoading: false
    }
  },
  components: {
    'vue-google-autocomplete': VueGoogleAutocomplete,
  },
  methods: {
    ...mapActions(['setCatalogAddressAction', 'parseCatalogAddressAction', 'getUserCurrentPositionAction', 'setHomeAction']),
    search(){
      if(this.isInHomeScreen){
        this.locationError = true;
        document.getElementById('catalogAutocomplete').focus()
        setTimeout(() => {
          this.locationError = false;
        }, 1000)
      }
      else{
        this.$root.$emit('openSearch');
      }
    },
    currentPosition(){
      this.isCurrentPositionLoading = true;
      this.getUserCurrentPositionAction({reload:false})
          .then((address) => {
            document.getElementById('catalogAutocomplete').value = address;
            this.isCurrentPosition = true;
            this.isCurrentPositionLoading = false;
          })
          .catch((error_code) => {
            this.locationPermissionError = this.$t(error_code) + '... ' + this.$t('enter_your_address_for_see_merchants');
            this.isCurrentPositionLoading = false;
          });
    },
    async selectAddress(e){ // Add address by autocomplete
      this.autocompleteData = true;
      this.addressError = false;
      this.address = e;
      if(!this.address.street_number){
        this.addressError = this.$t('address_no_street_number');
      }
      this.isCurrentPosition = false;
    },
    async saveSetup(){
      this.addressError = false;
      this.setupLoading = true;

      if(this.address){
        if(!this.address.street_number){
          this.addressError = this.$t('address_no_street_number');
          this.setupLoading = false;
          return;
        }

        this.locationLoading = true;
        await this.setCatalogAddressAction({address: this.address.street_number + ' ' + this.address.route, city: this.address.locality, zip: this.address.postal_code, country: this.address.country, lat:this.address.latitude, lng:this.address.longitude, reload: false});
        this.locationLoading = false;
      }
      else if(this.address === false && this.isCurrentPosition === false){
        await this.parseAddress();
      }
      this.setupLoading = false;
      if(this.addressError){
        return;
      }
      this.setHomeAction();
    },
    parseAddress(){ // Add address by address value
      return new Promise((resolve, reject) => {
        const address = document.getElementById('catalogAutocomplete').value;
        if(address === '' || address === null){
          this.addressError = this.$t('address_no_address');
          resolve();
          return;
        }
        this.locationLoading = true;

        if(!this.autocompleteData){ // Send to api server
          this.parseCatalogAddressAction({address: address, reload: false})
              .then(() => {
                this.locationLoading = false;
                resolve();
              })
              .catch((error) => {
                this.error = error;
                this.locationLoading = false;
                resolve();
              });
        }

      });
    },
  },
  computed: {
    ...mapGetters(['isInHomeScreen', 'filter']),

    scheduled(){
      return moment.unix(this.filter.whenTimestamp).locale("fr").format('ddd D • HH:mm');
    },
  }
}
</script>
