<template>
  <div class="merchant-item">
    <div class="item__images" v-if="merchant.images">


      <hooper class="image__slider" v-if="merchant.images.length > 1 && merchant.available" infinite-scroll :wheel-control="false">
        <slide v-for="image in merchant.images"
               :style="{'background-image': 'url('+image+')'}">
          <a :href="'/' + merchant.slug" class="action-zone"></a>
        </slide>
        <hooper-navigation slot="hooper-addons"></hooper-navigation>
      </hooper>

      <div class="image"
           v-if="merchant.images.length === 1 || !merchant.available && merchant.nextOpen"
           :style="{'background-image': 'url('+merchant.images[0]+')'}"
           @click="$router.push({name: 'merchant', params: {merchantSlug: merchant.slug}, query: {source:'marketplace'}})"></div>

      <div class="image__unavailable" v-if="!merchant.available && merchant.nextOpen"  @click="$router.push({name: 'merchant', params: {merchantSlug: merchant.slug}, query: {source:'marketplace'}})">
        <div class="available-at">
          <i class="material-icons notranslate">history</i>
          <span>Ouvre {{merchant.nextOpen}}</span>
        </div>
      </div>



      <div class="image__discount" v-if="merchant.discount && merchant.available">
        <div class="discount__box" v-if="merchant.discount.type === 'free_delivery' && merchant.discount.minValue.value !== 0">
          <i class="material-icons notranslate">local_offer</i>
          {{ $t('free_delivery_from', {price: merchant.discount.minValue.price}) }}
        </div>
        <div class="discount__box" v-else-if="merchant.discount.type === 'free_delivery' && merchant.discount.minValue.value === 0">
          <i class="material-icons notranslate">local_offer</i>
          {{ $t('free_delivery') }}
        </div>
        <div class="discount__box" v-else>
          <i class="material-icons notranslate">local_offer</i>
          Promotion
        </div>
      </div>



    </div>


    <div data-cy="merchant-item-infos" class="item__title" @click="$router.push({name: 'merchant', params: {merchantSlug: merchant.slug}, query: {source:'marketplace'}})">
      <strong>{{merchant.name}}</strong>
      <span class="duration">
        <i class="material-icons notranslate">schedule</i>
        {{merchant.duration}}
      </span>
    </div>
    <div class="item__data" @click="$router.push({name: 'merchant', params: {merchantSlug: merchant.slug}, query: {source:'marketplace'}})">
      <div class="meta">
        <small>{{merchant.meta}}</small>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import { Hooper, Slide, Pagination as HooperPagination, Navigation as HooperNavigation} from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  props: ['merchant'],
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination
  },
  data(){
    return {

    }
  },
  methods: {
    ...mapActions([])
  },
  computed: {
    ...mapGetters([]),
  },
  mounted() {
  }
}
</script>
